import React from 'react'
import Header from './components/header'
import Footer from './components/footer'
import Main from './components/main'
import './styles/mint.css'
import './styles/common.css'
import MintPage from './MintPage'

function App() {
  return (
    <div className="common">
      <Header />
      <div className="container">
        <Main />
      </div>
      <Footer />
    </div>
  )
}

export default App
