import React from 'react'
import '../styles/common.css'
import '../styles/footer.css'

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-component footer-component-logo">
          <a href="">
            <img src="/config/images/Logo_transparenc1.png" alt="" />
          </a>
        </div>
        <div className="footer-component footer-component-icon-main">
          <div className="footer-compnent-icon">
            <a
              className="footer-compnent-icon-circle-twitter"
              href="https://twitter.com/Cyberstella_"
            >
              <img
                className="icon"
                src="/config/images/vector/Vector-twitter.png"
                alt=""
              />
            </a>
          </div>
          <div className="footer-compnent-icon">
            <a
              className="footer-compnent-icon-circle-telegram"
              href="https://t.me/cyberstella_official"
            >
              <img
                className="icon"
                src="/config/images/vector/Vector-telegram.png"
                alt=""
              />
            </a>
          </div>
          <div className="footer-compnent-icon">
            <a
              className="footer-compnent-icon-circle-medium"
              href="https://medium.com/@Cyberstella"
            >
              <img
                className="icon"
                src="/config/images/vector/Vector-m.png"
                alt=""
              />
            </a>
          </div>

          <div className="footer-compnent-icon">
            <a
              className="footer-compnent-icon-circle-discord"
              href="https://discord.gg/Cyberstella"
            >
              <img
                className="icon-discord"
                src="/config/images/vector/Discord.png"
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="footer-component footer-component-company">
          ©2022 Cyberstella | All rights reserved.
        </div>
      </footer>
    )
  }
}

export default Footer
