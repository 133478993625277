import React from 'react';
import '../../styles/common.css'
import '../../styles/explore.css'
import '../../styles/faq.css'
import '../../styles/common.css'

class Faq extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_faq:false ,
      second_faq:false ,
      third_faq:false ,
      forth_faq:false
    };
    this.changeFirst = this.changeFirst.bind(this);
    this.changeSecond = this.changeSecond.bind(this);
    this.changeThird = this.changeThird.bind(this);
    this.changeForth = this.changeForth.bind(this);
  }

  changeFirst(e) {
    this.setState((state) => ({
      first_faq: !state.first_faq
    }));
  }
  changeSecond(e) {
    this.setState((state) => ({
      second_faq: !state.second_faq
    }));
  }
  changeThird(e) {
    this.setState((state) => ({
      third_faq: !state.third_faq
    }));
  }
  changeForth(e) {
    this.setState((state) => ({
      forth_faq: !state.forth_faq
    }));
  }


  render() {
    return (
      <section className='explore-component faq section' id='menu_faq'>
        <div className='faq-component'>
          <div className='faq-left'>
            <h2 className='explore-title faq-main-title'>
              <div className='faq-title-first'>FREQUENTLY</div>
              <div>ASK<span className='green-text'> QUESTIONS</span></div>
              </h2>
            <div>
              <div>
                <div className='faq-title-component'>
                  <h3 className='faq-title'>
                    {this.state.first_faq ?
                      <div className='faq-title-title-open'>
                        <div>01　</div>
                        <div>How will Crew be used in Cyberstella?</div>
                      </div>:
                      <div className='faq-title-title'>
                      <div>01　</div>
                      <div>How will Crew be used in Cyberstella?</div>
                    </div>
                    }
                    <div onClick={this.changeFirst} className='faq-title-expand'>+</div>
                  </h3>
                  {this.state.first_faq ?
                    <p className='faq-title-description'>
                      Crews can be sent on exploration trips to distant planets with a chance of discovering materials or even treasure, on scouting embarkations to search for new crew members or on missions for rewards both in tokens and materials. At a later date, players will be able to battle other crews in PvP.
                    </p>:
                    <p></p>
                  }
                </div>
                <div className='faq-title-component'>
                  <h3 className='faq-title'>
                    {this.state.second_faq ?
                      <div className='faq-title-title-open'>
                        <div>02　</div>
                        <div>What are other benefits of holding a Crew?</div>
                      </div>:
                      <div className='faq-title-title'>
                      <div>02　</div>
                      <div>What are other benefits of holding a Crew?</div>
                    </div>
                    }
                    <div onClick={this.changeSecond} className='faq-title-expand'>+</div>
                  </h3>
                  {this.state.second_faq ?
                    <p className='faq-title-description'>Those who hold crew member NFTs gain access to the changeable world of Cyberstella through mangas, short films and various storylines relating to the very characters owned by gamers. Players can immerse themselves in a story universe which can be influenced by them.</p>:
                    <p></p>
                  }
                </div>
                <div className='faq-title-component'>
                  <h3 className='faq-title'>
                    {this.state.third_faq ?
                      <div className='faq-title-title-open'>
                        <div>03　</div>
                        <div>What are the types of rarities?</div>
                      </div>:
                      <div className='faq-title-title'>
                      <div>03　</div>
                      <div>What are the types of rarities?</div>
                    </div>
                    }
                    <div onClick={this.changeThird} className='faq-title-expand'>+</div>
                  </h3>
                  {this.state.third_faq ?
                    <p className='faq-title-description'>Three main classes of crew member NFTs exist: humans, beats and androids. Each different NFT will have varying stats according to rarity level, which can be common, uncommon, rare or if you’re very lucky, epic and legendary.</p>:
                    <p></p>
                  }
                </div>
                <div className='faq-title-component'>
                  <h3 className='faq-title'>
                    {this.state.forth_faq ?
                      <div className='faq-title-title-open'>
                        <div>04　</div>
                        <div>Can I trade NFTs?</div>
                      </div>:
                      <div className='faq-title-title'>
                      <div>04　</div>
                      <div>Can I trade NFTs?</div>
                    </div>
                    }
                    <div onClick={this.changeForth} className='faq-title-expand'>+</div>
                  </h3>
                  {this.state.forth_faq ?
                    <p className='faq-title-description'>An open marketplace is in the conceptual stage and may arrive at a later date following the game launch.</p>:
                    <p></p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='faq-component'>
          <img className='faq-image' src="/config/images/faq/faq.png" alt="" />
        </div>
      </section>
    );
  }
}

export default Faq;