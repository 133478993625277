import React from 'react'
import Slider from 'react-slick'

import '../../styles/explore.css'
import '../../styles/common.css'
import '../../styles/collection.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class Collection extends React.Component {
  render() {
    var settings = {
      dots: true,
      autoplay: true,
      nextArrow: false,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <section className="explore-component collection section">
        <div className="collection-component collection-main">
          <div className="collection-left">
            <div className="explore-pre-title collection-pre-title">
              WHAT’S INCLUDED
            </div>
            <h2 className="collection-title">
              <div className="explore-title collection-title-component">
                IN OUR
              </div>
              <div className="explore-title collection-title-component green-text">
                COLLECTION
              </div>
            </h2>
            {/* <div className='collection-direction'>
              <button className='collection-direction-side collection-direction-left'> &lt; </button>
              <button className='collection-direction-side collection-direction-right'> &gt;</button>
            </div> */}
          </div>
        </div>
        <div className="collection-component collection-right">
          <Slider
            {...settings}
            className="slide-image"
          >
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/1_Common_01.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/1_Common_02.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/1_Common_03.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/2_Uncommon_01.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/2_Uncommon_02.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/3_Rare_01.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/3_Rare_02.png"
                alt=""
              />
            </div>
            <div>
              <img
                className="collection-image"
                src="/config/images/collection/3_Rare_03.png"
                alt=""
              />
            </div>
          </Slider>
        </div>
      </section>
    )
  }
}

export default Collection
