import '../styles/common.css'
import { useSelector } from 'react-redux'
import '../styles/header.css'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useDispatch } from 'react-redux'
import { connect } from '../redux/blockchain/blockchainActions'

const Header = () => {
  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)

  const BeforeConnect = () => {
    return (
      <div className="header-component header-button">
        <button
          className="button-common button-header green-button connect"
          onClick={(e) => {
            e.preventDefault()
            dispatch(connect())
          }}
        >
          CONNECT
          <div className="button-deco-common button-deco-first"></div>
          <div className="button-deco-common button-deco-second"></div>
          <div className="button-deco-common button-deco-third"></div>
          <div className="button-deco-common button-deco-forth"></div>
        </button>
      </div>
    )
  }

  const AftarConnect = () => {
    return <div></div>
  }

  const ConnectButton = () => {
    return (
      <React.Fragment>
        {blockchain.account === '' || blockchain.smartContract === null ? (
          <BeforeConnect />
        ) : (
          <AftarConnect />
        )}
      </React.Fragment>
    )
  }

  return (
    <header className="header-main" id='menu_home'>
      <div className="header-component header-logo">
        <a href="">
          <img
            className="header-logo"
            src="/config/images/Logo_transparenc1.png"
            alt=""
          />
        </a>
      </div>
      <div className="header-menu-button">
        <nav>
          <ul className="header-component header-menu">
            <li className="header-menu-component"><AnchorLink href="#menu_home" className="header-menu-component">HOME</AnchorLink></li>
            <li className="header-menu-component"><AnchorLink href="#menu_about" className="header-menu-component">ABOUT</AnchorLink></li>
            <li className="header-menu-component"><AnchorLink href="#menu_features" className="header-menu-component">FEATURES</AnchorLink></li>
            <li className="header-menu-component"><AnchorLink href="#menu_roadmap" className="header-menu-component">ROADMAP</AnchorLink></li>
            <li className="header-menu-component"><AnchorLink href="#menu_faq" className="header-menu-component">FAQ</AnchorLink></li>
            {/*<li><AnchorLink href="#menu3" className="header-menu-component">a</AnchorLink></li>
            <li className="header-menu-component"><a href='#menu3'>ABOUT</a></li>
            <li className="header-menu-component">FEATURES</li>
            <li className="header-menu-component">ROADMAP</li>
            <li className="header-menu-component">FAQ</li> */}
          </ul>
        </nav>
        <ConnectButton />
      </div>
      
    </header>
  )
}

export default Header
