import React from 'react'
import MintTest from './main-components/mintTest'
import Explore from './main-components/explore'

class Main extends React.Component {
  render() {
    return (
      <div>
        <MintTest />
        <Explore />
      </div>
    )
  }
}

export default Main
