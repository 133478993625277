import React from 'react';
import '../../styles/explore.css'
import '../../styles/features.css'

class Features extends React.Component {
  render() {
    return (
      <section className='explore-component feature section' id='menu_features'>
        <h2>
          <div className='explore-pre-title features-pre-title'>OUR NFTs’</div>
          <div className='explore-title features-title'>SPECIAL<span className='green-text'> FEATURES</span></div>
        </h2>
        <div className='features-main'>
          <div className='features'>
            <div className='features-left'>
              <div>
                <img className='feature-img' src="/config/images/features/features-left.png" alt="" />
              </div>
              <h3 className='feature-title'>BEAST NFT</h3>
              <p className='features-description'>Space beasts are among the strongest of crew members who make up for a lack of dexterity with brute force. Highly useful in a PvP battle with a rival Crew.</p>
            </div>
          </div>
          <div className='features'>
            <div className='features-center'>
              <div className='features-img'>
                <img className='feature-img' src="/config/images/features/features-center.png" alt="" />
              </div>
              <h3 className='feature-title'>HUMAN NFT</h3>
              <p className='features-description'>Resilient and ambitious, humans are ideal middle of the road colonizers due to their adaptability and ability to take on any task with a moderately good stat base across all disciplines.</p>
            </div>
          </div>
          <div className='features'>
            <div className='features-right'>
              <div className='features-img'>
                <img className='feature-img' src="/config/images/features/features-right.png" alt="" />
              </div>
              <h3 className='feature-title'>ANDROID NFT</h3>
              <p className='features-description'>The sophistication of computer chips in 2222 means androids are invaluable for their great analytical ability and endless power to calculate and solve problems.</p>
              <p className='features-description features-description-add'>(Not available upon genesis)</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;