import React from 'react';
import '../../styles/explore.css'
import '../../styles/map.css'

class Map extends React.Component {
  render() {
    return (
      <section className='explore-component map section' id='menu_roadmap'>
        <h2>
          <div className='explore-pre-title map-pre-title'>OUR</div>
          <div className='explore-title map-title'>ROAD<span className='green-text'> MAP</span></div>
        </h2>
        <div>
          <img className='map-pc-image' src="/config/images/map/map-pc.png" alt="" />
        </div>
        {/* <div className='map-smart'>
          <img className='map-smart-image' src="/config/images/map/map-smart-1.png" alt="" />
          <img className='map-smart-image image-2' src="/config/images/map/map-smart-2.png" alt="" />
        </div> */}
      </section>
    );
  }
}

export default Map;