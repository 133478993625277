import React from 'react';
import Collection from '../explore-components/collection';
import About from '../explore-components/about';
import Details from '../explore-components/details';
import Features from '../explore-components/features';
import Map from '../explore-components/map';
import Faq from '../explore-components/faq';

class Explore extends React.Component {
  render() {
    return (
      <div >
        
        <About />
        <Details />
        <Collection />
        <Features />
        <Map />
        <Faq />
        
      </div>
    );
  }
}

export default Explore;