import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLoading, Oval } from '@agney/react-loading'
import { connect } from '../../redux/blockchain/blockchainActions'
import { fetchData } from '../../redux/data/dataActions'
import * as s from '../../styles/globalStyles'
import styled from 'styled-components'
import BN from 'bn.js'
import '../../styles/mint-page.css'
import '../../styles/common.css'
import '../../styles/footer.css'

const error_message = 'Invalid input'

const MintTest = (props) => {
  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)
  const data = useSelector((state) => state.data)
  const [merkle, setMerkle] = useState([])
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
  })

  const { indicatorEl } = useLoading({
    loading: claimingNft,
    indicator: <Oval width="24" />,
  })

  const claimNFTs = () => {
    let cost = data.cost
    let gasLimit = CONFIG.GAS_LIMIT
    let method = null
    let totalCostWei = new BN(cost.toString()).muln(mint_number)
    let totalGasLimit = String(gasLimit * mint_number)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    if (data.presale) {
      method = blockchain.smartContract.methods.presaleMint(
        mint_number,
        merkle.hexProof
      )
    } else if (data.publicSale) {
      method = blockchain.smartContract.methods.publicMint(mint_number)
    }
    method
      .send({
        //gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `Thanks for minting Cyberstella NFT!! You can check them at TofuNFT.`
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getMerkleData = (account) => {
    fetch('/.netlify/functions/merkletree?address=' + account)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result)
          setMerkle(result)
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((error) => {
        console.error('通信に失敗しました', error)
      })
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData()
    if (blockchain.account) {
      getMerkleData(blockchain.account)
    }
  }, [blockchain.account])

  const BeforeConnect = () => {
    return (
      <div className="mint-page-button">
        {/* TODO: gray green button */}
        <button
          className="button-common button-header gray-button connect-wallet"
          onClick={(e) => {
            e.preventDefault()
            dispatch(connect())
          }}
        >
          CONNECT WALLET
          <div className="button-deco-common button-deco-first"></div>
          <div className="button-deco-common button-deco-second"></div>
          <div className="button-deco-common button-deco-third"></div>
          <div className="button-deco-common button-deco-forth"></div>
        </button>
      </div>
    )
  }

  const AftarConnect = () => {
    return (
      <div className="mint-page-button ">
        <button
          className={
            mint_number >= 1 &&
            mintAmount <= 10 &&
            (data.presale || data.publicSale)
              ? 'button-common button-header green-button connect-wallet mint-page-display'
              : 'button-common button-header gray-button connect-wallet mint-page-display'
          }
          onClick={(e) => {
            e.preventDefault()
            claimNFTs()
            getData()
          }}
        >
          BUY NOW
          <div className="button-deco-common button-deco-first"></div>
          <div className="button-deco-common button-deco-second"></div>
          <div className="button-deco-common button-deco-third"></div>
          <div className="button-deco-common button-deco-forth"></div>
        </button>
        {/* TODO: pubsale時には消す*/}
        <div className="whitelist-msg">
          <p>
            {merkle['hexProof'] ? 'You are in the whitelist.' : ''}
            {!merkle['hexProof'] ? 'You are not in the whitelist' : ''}
          </p>
          <p className="whitelist-addr">{blockchain.account}</p>
        </div>
      </div>
    )
  }

  const MintButton = () => {
    return (
      <React.Fragment>
        <s.SpacerXSmall />
        {blockchain.account === '' || blockchain.smartContract === null ? (
          <BeforeConnect />
        ) : (
          <AftarConnect />
        )}
      </React.Fragment>
    )
  }

  const [mint_number, setMintNumber] = useState(1)
  const [mint_cost, setMintCost] = useState(1)

  const minusMintMumber = () => {
    getData()
    if (mint_number == 1) {
      setMintNumber(error_message)
    } else {
      if (mint_number == error_message) {
        setMintNumber(CONFIG.MAX_MINT_AMOUNT_PER_TX)
      } else {
        setMintNumber(mint_number - 1)
      }
    }
  }

  const plusMintMumber = () => {
    getData()
    if (mint_number == CONFIG.MAX_MINT_AMOUNT_PER_TX) {
      setMintNumber(error_message)
    } else {
      if (mint_number == error_message) {
        setMintNumber(1)
      } else {
        setMintNumber(mint_number + 1)
      }
    }
  }

  const maximizeMintMumber = () => {
    getData()
    setMintNumber(CONFIG.MAX_MINT_AMOUNT_PER_TX)
  }

  const hey = () => {
    console.log('hey!')
  }

  return (
    <section className="mint-page section">
      <div className="mint-page-left">
        <h1 className="mint-page-title">
          <div className="green-text">Cyberstella</div>
          <div className="mint-page-2nd">STARTER PACKAGE</div>
        </h1>
        <p className="mint-page-description">
          To embark on a space journey in Cyberstella, you’re going to need a
          Crew and space cash. Purchase a starter pack today to receive NFTs,
          in-game tokens and governance tokens to unlock an expansive universe
          designed to reward those who dare to dream.
        </p>
        <div className="mint-page-icons">
          <div className="footer-component mint-page-icon-main">
            <div className="footer-compnent-icon">
              <a
                className="footer-compnent-icon-circle-twitter"
                href="https://twitter.com/Cyberstella_"
              >
                <img
                  className="icon"
                  src="/config/images/vector/Vector-twitter.png"
                  alt=""
                />
              </a>
            </div>
            <div className="footer-compnent-icon">
              <a
                className="footer-compnent-icon-circle-telegram"
                href="https://t.me/cyberstella_official"
              >
                <img
                  className="icon"
                  src="/config/images/vector/Vector-telegram.png"
                  alt=""
                />
              </a>
            </div>
            <div className="footer-compnent-icon">
              <a
                className="footer-compnent-icon-circle-medium"
                href="https://medium.com/@Cyberstella"
              >
                <img
                  className="icon"
                  src="/config/images/vector/Vector-m.png"
                  alt=""
                />
              </a>
            </div>
            <div className="footer-compnent-icon">
              <a
                className="footer-compnent-icon-circle-discord"
                href="https://discord.gg/Cyberstella"
              >
                <img
                  className="icon-discord"
                  src="/config/images/vector/discord.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* ここから右 */}
      <div className="mint-page-right">
        <div className="mint-page-right-main">
          <img
            className="mint-page-image"
            alt=""
            src="/config/images/mint-page/mint-page_3_gold.png"
          />
          {/*
                        <img
            className="mint-page-image-soldout"
            alt=""
            src="/config/images/mint-page/Soldout_MIntSite.png"
          />

          */}

          <div className="mint-page-description mint-page-display">
            <div className="mint-page-description-title">MINT</div>
            <p className="mint-page-description-description">
              Enter the amount of NFTs you would like to buy
            </p>
          </div>
          <div className="mint-page-input mint-page-display">
            <div>
              <button
                className="mint-page-input-button mint-page-input-left"
                onClick={() => {
                  minusMintMumber()
                }}
              >
                -
              </button>
            </div>
            <div id="sss">
              {mint_number == error_message ? (
                <input
                  className="mint-page-input-input-alart"
                  type="text"
                  value={mint_number}
                  onChange={hey}
                />
              ) : (
                <input
                  className="mint-page-input-input"
                  type="text"
                  value={mint_number}
                  onChange={hey}
                />
              )}
            </div>
            <div>
              <button
                className="mint-page-input-button mint-page-input-right"
                onClick={() => {
                  plusMintMumber()
                }}
              >
                +
              </button>
            </div>
          </div>
          {mint_number == error_message ? (
            <div className="mint-page-error-message">
              Please insert number from 1 - 10
            </div>
          ) : (
            <div></div>
          )}
          <div className="mint-page-info mint-page-display">
            <div className="mint-page-info-main">
              <div className="mint-page-info-number">
                <span>
                  {/* TODO: パック数を変更 */}
                  {!!data.totalSupply
                    ? data.totalSupply / 3 - 480 + ' / 20 packs'
                    : ''}
                </span>
              </div>
              <div>
                <button
                  className="small-button"
                  onClick={() => {
                    maximizeMintMumber()
                  }}
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="mint-page-info-max"></div>
          </div>
          <div className="mint-page-total mint-page-display">
            <div className="mint-page-total-total">Total:</div>
            <div className="mint-page-total-eth">
              {isNaN(mint_number)
                ? '- AVAX'
                : mint_number * CONFIG.CURRENT_MINT_PRICE + ' AVAX'}
            </div>
          </div>
          <MintButton config={CONFIG} />
        </div>
      </div>
    </section>
  )
}

export default MintTest
