import React from 'react';
import '../../styles/explore.css'
import '../../styles/about.css'
import '../../styles/common.css'

class About extends React.Component {
  render() {
    return (
      <section className='explore-component about section' id='menu_about'>
        <div className='about-component'>
          <h2 className='explore-component-right'>
            <div className='explore-pre-title about-pre-title'>MORE TO KNOW</div>
            <div className='explore-title about-title'><span className='green-text'>ABOUT </span>US</div>
            <p className='description-22 about-description'>World renowned Japanese developers have come together with crypto-native entrepreneurs to form Murasaki, a blockchain mobile game and media studio which conceptualized and designed Cyberstella.</p>
          </h2>
        </div>
        <div className='about-component'>
          <img className='explore-component-left' src="/config/images/about/about.png" alt="" />
        </div>
      </section>
    );
  }
}

export default About;