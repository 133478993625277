import React from 'react'
import '../../styles/explore.css'
import '../../styles/details.css'

class Details extends React.Component {
  render() {
    return (
      <section className="explore-component details section">
        <h2>
          <div className="explore-pre-title details-pre-title">OUR</div>
          <div className="explore-title details-title">
            PACKAGE<span className="green-text"> DETAILS</span>
          </div>
        </h2>
        <ul className="details-medals">
          <li className="details-medals-component-main">
            <h3 className="details-medals-components">
              <div>
                <img
                  className="medals-image"
                  src="/config/images/details/gold.png"
                  alt=""
                />
              </div>
              <div className="details-medals-components-title">GOLD</div>
            </h3>
            <p className="details-medals-component-description">
              <p>
                <span className="font-700">NFTs: </span>Uncommon,Uncommon,Rare
              </p>
              <p> 2022/11/25 AM 8:00 ~ 2022/11/29 AM 7:00 (UTC) </p>
            </p>
          </li>
          <li className="details-medals-component-main">
            <h3 className="details-medals-components">
              <div>
                <img
                  className="medals-image"
                  src="/config/images/details/silver.png"
                  alt=""
                />
              </div>
              <div className="details-medals-components-title">SILVER</div>
            </h3>
            <p className="details-medals-component-description">
              <p>
                <span className="font-700">NFTs: </span>Common,Common,Rare
              </p>
              <p> 2022/11/18 AM 8:00 ~ 2022/11/22 AM 7:00 (UTC) </p>
            </p>
          </li>
          <li className="details-medals-component-main">
            <h3 className="details-medals-components">
              <div>
                <img
                  className="medals-image"
                  src="/config/images/details/bronze.png"
                  alt=""
                />
              </div>
              <div className="details-medals-components-title">BRONZE</div>
            </h3>
            <p className="details-medals-component-description">
              <p>
                <span className="font-700">NFTs: </span>Common,Common,Uncommon
              </p>
              <p>2022/11/11 AM 8:00 ~ 2022/11/15 AM 7:00</p>
            </p>
          </li>
        </ul>
      </section>
    )
  }
}

export default Details
